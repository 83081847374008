<template>
  <a-form-model
    style="background: #ffffff;min-height:900px;padding-top: 20px;"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item ref="name" label="姓名" prop="name">
      <a-input
        placeholder="请输入姓名"
        v-model="form.name"
        :disabled="show == 1 ? true : false"
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item label="性别" prop="sex">
      <a-select
        v-model="form.sex"
        placeholder="请选择性别"
        :disabled="show == 1 ? true : false"
      >
        <a-select-option value="男">
          男
        </a-select-option>
        <a-select-option value="女">
          女
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item ref="contact" label="联系方式" prop="contact">
      <a-input
        placeholder="请输入联系方式"
        :maxLength="11"
        v-model="form.contact"
        :disabled="show == 1 ? true : false"
        @blur="
          () => {
            $refs.contact.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item label="所属组织" prop="org">
      <a-select
        v-model="form.org"
        placeholder="请选择所属组织"
        @change="handleChange"
        :disabled="show == 1 ? true : false"
      >
        <a-select-option
          :value="item.dbId"
          v-for="(item, index) in deptList"
          :key="index"
        >
          {{ item.dept_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="角色" prop="role">
      <a-select
        v-model="form.role"
        placeholder="请选择角色"
        @change="handleChange2"
        :disabled="show == 1 ? true : false"
      >
        <a-select-option
          :value="item.dbId"
          v-for="(item, index) in roleList"
          :key="index"
        >
          {{ item.role_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item ref="account" label="登录账号" prop="account">
      <a-input
        placeholder="请输入登录账号"
        v-model="form.account"
        :disabled="show == 1 ? true : false"
        @blur="
          () => {
            $refs.account.onFieldBlur();
          }
        "
      />
    </a-form-model-item>

    <a-form-model-item
      ref="password"
      :label="this.id ? '修改密码' : '登录密码'"
      prop="password"
    >
      <span class="password-ok">*</span>
      <a-input
        placeholder="请输入登录密码"
        v-model="form.password"
        type="password"
        :disabled="show == 1 ? true : false"
      />
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 8, offset: 8 }"
      style="text-align: center;"
    >
      <a-button type="primary" @click="onSubmit" v-if="!id && !show">
        立即创建
      </a-button>
      <a-button type="primary" @click="onSubmit" v-if="id && !show">
        立即修改
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetForm">
        取消
      </a-button>
      <!--      <a-button style="margin-left: 10px;" @click="resetForm">-->
      <!--        返回-->
      <!--      </a-button>-->
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { createUser, showUser, editUser } from "@/api/permission/user/index";
import { getDeptList } from "@/api/permission/organization/index";
import { getRoleList } from "@/api/permission/role/index";
// import { delete } from 'vue/types/umd';
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      other: "",
      form: {
        name: "",
        sex: undefined,
        contact: "",
        org: undefined,
        role: undefined,
        account: undefined,
        password: undefined
      },
      id: 0,
      show: 0,
      deptList: [],
      roleList: [],
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" }
        ],
        org: [{ required: true, message: "请选择所属组织", trigger: "change" }],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
        account: [
          { required: true, message: "请输入登录账号", trigger: "blur" }
        ]
        // password: [
        //   { required: true, message: "请输入登录密码", trigger: "blur" }
        // ]
      }
    };
  },
  created() {
    this.getOrganizations();
    this.getRoles();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      showUser({
        userId: this.id
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.form.name = res.data.nick_name;
          this.form.sex = res.data.sex;
          this.form.contact = res.data.phonenumber;
          this.form.org = res.data.dept_id;
          this.form.role = res.data.roles[0]?.dbId;
          this.form.account = res.data.user_name;
          // this.form.password = res.data.password;
        }
        console.log(this.form);
      });
    }
    if (this.$route.query.show) {
      this.show = this.$route.query.show;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (this.form.contact && !/^1[3456789]\d{9}$/.test(this.form.contact)) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        if (valid) {
          if (this.id) {
            this.updateUser();
          } else {
            this.addUser();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addUser() {
      let _this = this;
      if (!_this.form.password) {
        _this.$message.warning("请填写密码");
        return;
      }
      createUser({
        nick_name: _this.form.name,
        sex: _this.form.sex,
        phonenumber: _this.form.contact,
        dept_id: _this.form.org,
        roleIds: [_this.form.role],
        user_name: _this.form.account,
        password: _this.form.password,
        status: 0
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.$message.success("新增成功", function() {
            _this.$router.push({ path: "/index/auth/users/usersList" });
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    updateUser() {
      let _this = this;
      let data = {
        dbId: _this.id,
        nick_name: _this.form.name,
        sex: _this.form.sex,
        phonenumber: _this.form.contact,
        dept_id: _this.form.org,
        roleIds: [_this.form.role],
        user_name: _this.form.account,
        password: _this.form.password
      };
      if (!_this.form.password) {
        // _this.$message.warning("不填写密码默认上次设置的密码");
        delete data.password;
        console.log(data);
        // return;
      }
      editUser(data).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.$message.success("编辑成功", function() {
            _this.$router.push({ path: "/index/auth/users/usersList" });
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({ path: "/index/auth/users/usersList" });
    },
    getOrganizations() {
      this.deptList = [];
      getDeptList({}).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.deptList = res.data;
        }
      });
    },
    getRoles() {
      this.roleList = [];
      getRoleList({}).then(res => {
        if (res.code == 200) {
          this.roleList = res.rows;
        }
      });
    },
    handleChange(value) {
      this.form.org = value;
    },
    handleChange2(value) {
      this.form.role = value;
    }
  }
};
</script>

<style scoped>
.password-ok {
  position: absolute;
  top: -10px;
  left: -80px;
  color: red;
}
</style>
